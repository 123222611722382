import { Col, Card, Image, Row, Typography } from "antd";
import React from "react";
import accommodationImg from "./images/CL23_CAF_Pod_Accommodation.jpg";
import chatImg from "./images/CL23_CAF_Pod_Students.jpg";
import campusImg from "./images/CL23_CAF_Pod_Tour.jpg";

export interface ApplyResultProps {
  applicationReferenceNumber: string;
  courseTitle: string;
  courseUCASCode: string;
  courseLocationCode: string;
}

const ucasClosures: { close: Date; open: Date }[] = [
  // {
  //   close: new Date("2024-07-01T08:00:00"),
  //   open: new Date("2024-08-06T09:00:00"),
  // },
  {
    close: new Date("2024-07-29T18:00:00"),
    open: new Date("2024-08-06T09:00:00"),
  },
  {
    close: new Date("2024-08-09T14:00:00"),
    open: new Date("2024-08-15T08:00:00"),
  },
];

const UCASReferralInstructions: React.FC<{ courseUCASCode: string }> = ({
  courseUCASCode,
}) => (
  <>
    <Typography.Title level={2}>
      Add your Clearing choice to your UCAS Hub
    </Typography.Title>
    <ol>
      <li>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ucas.com/dashboard"
        >
          Log in to your UCAS Hub
        </a>{" "}
        and click 'Add clearing choice'
      </li>
      <li>Select H36 for the University of Hertfordshire</li>
      <li>
        Add your course code: <strong>{courseUCASCode}</strong>
      </li>
      <li>Wait for your place to be confirmed</li>
    </ol>
  </>
);

export const Offer: React.FC<ApplyResultProps> = (props) => {
  const ucasOpen = React.useMemo(() => {
    const now = new Date();
    for (const closure of ucasClosures)
      if (now > closure.close && now < closure.open) return false;
    return true;
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Typography.Title>Congrats, you did it 🎉 🥳 🙌</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Thanks for submitting your Clearing application, we’re thrilled to
            offer you a place to study {props.courseTitle} with us,
            congratulations!
          </p>
          {ucasOpen && (
            <UCASReferralInstructions courseUCASCode={props.courseUCASCode} />
          )}
          <p>
            The reference number for your application is{" "}
            <Typography.Text strong>
              {props.applicationReferenceNumber}
            </Typography.Text>
            .
          </p>
          {(ucasOpen && (
            <p>
              Your offer is subject to proof of your qualifications and to you
              being eligible for Clearing. You’ll shortly receive an email with
              full details, along with your next steps.
            </p>
          )) || (
            <>
              <p>
                Your offer is subject to proof of your qualifications and to you
                being eligible for Clearing. You’ll shortly receive an email
                with details of how to accept your offer, along with your next
                steps.
              </p>
              <p>
                In the meantime, stick around and find out all you need to know
                about joining us at the University of Hertfordshire.
              </p>
            </>
          )}
        </Col>
      </Row>
      {!ucasOpen && (
        <Row gutter={20} className="outcome-cards">
          <Col xs={24} sm={8}>
            <Card
              cover={
                <Image
                  alt="campus arial drawing"
                  src={campusImg}
                  preview={false}
                />
              }
            >
              <Card.Meta
                title={
                  <a href="https://www.herts.ac.uk/study/open-days-and-events/take-a-campus-tour">
                    Book a campus tour
                  </a>
                }
                description="
              Come and visit us and take a look around our study facilities,
              social spaces and our accommodation."
              />
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card
              cover={
                <Image alt="students talking" src={chatImg} preview={false} />
              }
            >
              <Card.Meta
                title={
                  <a href="https://www.herts.ac.uk/life/unibuddy">
                    Chat to our students
                  </a>
                }
                description="
Want to know more about campus life and studying with us? Chat
            directly to our students and get a feel for life at Herts."
              />
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card
              cover={
                <Image
                  alt="student in bedroom"
                  src={accommodationImg}
                  preview={false}
                />
              }
            >
              <Card.Meta
                title={
                  <a href="https://www.herts.ac.uk/life/student-accommodation">
                    View our accommodation
                  </a>
                }
                description="
            Looking to live on campus? You can apply as soon as you get your
            Student ID. Get ahead and choose your room type now."
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export const Refer: React.FC<ApplyResultProps> = (props) => (
  <>
    <Row>
      <Col>
        <Typography.Title>We're reviewing your application 🧑‍💻</Typography.Title>
      </Col>
    </Row>
    <Row>
      <Col>
        <p>
          Thank you for submitting your Clearing application. It is now with an
          Admissions Tutor for review. We’ll be in touch as soon as possible
          with an update.
        </p>
        <p>
          The reference number for your application is{" "}
          <Typography.Text strong>
            {props.applicationReferenceNumber}
          </Typography.Text>
          .
        </p>
        <p>
          In the meantime, why not take a look around our website to discover
          more about Herts?
        </p>
      </Col>
    </Row>
    <Row gutter={20}>
      <Col xs={24} sm={8}>
        <Card
          cover={
            <Image alt="campus arial drawing" src={campusImg} preview={false} />
          }
        >
          <Card.Meta
            title={
              <a href="https://www.herts.ac.uk/study/open-days-and-events/take-a-campus-tour">
                Book a campus tour
              </a>
            }
            description="
              Come and visit us and take a look around our study facilities,
              social spaces and our accommodation."
          />
        </Card>
      </Col>
      <Col xs={24} sm={8}>
        <Card
          cover={<Image alt="students talking" src={chatImg} preview={false} />}
        >
          <Card.Meta
            title={
              <a href="https://www.herts.ac.uk/life/unibuddy">
                Chat to our students
              </a>
            }
            description="
Want to know more about campus life and studying with us? Chat
            directly to our students and get a feel for life at Herts."
          />
        </Card>
      </Col>
      <Col xs={24} sm={8}>
        <Card
          cover={
            <Image
              alt="student in bedroom"
              src={accommodationImg}
              preview={false}
            />
          }
        >
          <Card.Meta
            title={
              <a href="https://www.herts.ac.uk/life/student-accommodation">
                View our accommodation
              </a>
            }
            description="
            Looking to live on campus? You can apply as soon as you get your
            Student ID. Get ahead and choose your room type now."
          />
        </Card>
      </Col>
    </Row>
  </>
);

export interface RejectResultProps extends ApplyResultProps {
  ucasTariffMet: boolean;
  gcseEnglishMet: boolean;
  gcseMathsMet: boolean;
  twoPlusALevelRequirementMet?: boolean | null;
  courseSpecificConditionsMet?: boolean | null;
}

export const Reject: React.FC<RejectResultProps> = (props) => (
  <>
    <Row>
      <Col>
        <Typography.Title>
          We're sorry, we can't offer you a place 😔
        </Typography.Title>
      </Col>
    </Row>
    <Row>
      <Col>
        <p>
          Thank you for submitting your Clearing application. We’re sorry to
          inform you that your application has been unsuccessful, and we will
          not be able to offer you a place at this time.
        </p>
        <p>
          The reference number for your application is{" "}
          <Typography.Text strong>
            {props.applicationReferenceNumber}
          </Typography.Text>
          .
        </p>
        <p>
          Unfortunately, you do not meet the following requirements for this
          course:
        </p>
        <ul>
          {!props.ucasTariffMet && <li>UCAS Tariff</li>}
          {!props.gcseEnglishMet && <li>GCSE English</li>}
          {!props.gcseMathsMet && <li>GCSE Maths</li>}
          {props.courseSpecificConditionsMet === false && (
            <li>Course Specific Requirements</li>
          )}
        </ul>
        <p>
          Our partner colleges may offer courses to help you meet our
          requirements in future years.
        </p>
        <ul className="link-list">
          <li>
            <a href="https://www.hrc.ac.uk" target="_blank" rel="noreferrer">
              Hertford Regional College
            </a>
          </li>
          <li>
            <a href="https://www.nhc.ac.uk" target="_blank" rel="noreferrer">
              North Herts College
            </a>
          </li>
          <li>
            <a
              href="https://www.oaklands.ac.uk"
              target="_blank"
              rel="noreferrer"
            >
              Oakland College
            </a>
          </li>
          <li>
            <a
              href="https://www.westherts.ac.uk"
              target="_blank"
              rel="noreferrer"
            >
              West Herts College
            </a>
          </li>
        </ul>
        <p>
          Thank you once again for considering the University of Hertfordshire
          and good luck in your future studies.
        </p>
      </Col>
    </Row>
  </>
);
