import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { SentryLink } from "apollo-link-sentry";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

const cache = new InMemoryCache();

// @ts-ignore
window.cache = cache;

export const createClient = (apiUrl: string) => {
  const httpLink = new HttpLink({ uri: apiUrl });
  const sentryLink = new SentryLink();
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach((gqlError) => {
        if (gqlError.message.includes("Not Found")) return;
        if (gqlError.message.includes("Authentication Error")) return;
        if (gqlError.message.includes("Validation Error")) return;
        if (gqlError.message.includes("Internal Error")) return;

        Sentry.captureException(gqlError);
      });

    if (networkError) Sentry.captureException(networkError);
  });

  return new ApolloClient({
    cache: cache,
    link: from([sentryLink, errorLink, httpLink]),
  });
};
