import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
};

export type AutoOfferResult = {
  __typename?: 'AutoOfferResult';
  outcome: Scalars['String'];
  ucasTariffMet: Scalars['Boolean'];
  gcseEnglishMet: Scalars['Boolean'];
  gcseMathsMet: Scalars['Boolean'];
  twoPlusALevelRequirementMet?: Maybe<Scalars['Boolean']>;
  courseSpecificConditionsMet?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  submitApplication: SubmitApplicationResponse;
};


export type MutationSubmitApplicationArgs = {
  attributes: SubmitApplicationAttributes;
};

export type PublishedCourse = {
  __typename?: 'PublishedCourse';
  id: Scalars['ID'];
  title: Scalars['String'];
  courseCode: Scalars['String'];
  ucasCode: Scalars['String'];
  school: Scalars['String'];
  collegeLocations: Array<Scalars['String']>;
  complexConditions?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  publishedCourses: Array<PublishedCourse>;
};

export type SubmitApplicationAttributes = {
  firstName: Scalars['String'];
  surname: Scalars['String'];
  emailAddress: Scalars['String'];
  mobileNumber: Scalars['String'];
  mobileNumberRegion: Scalars['String'];
  ucasNumber?: Maybe<Scalars['String']>;
  course: Scalars['String'];
  college?: Maybe<Scalars['String']>;
  ucasPoints: Scalars['Int'];
  gcseEnglishGrade: Scalars['Int'];
  gcseMathsGrade: Scalars['Int'];
  twoPlusALevelRequirementMet: Scalars['Boolean'];
  courseSpecificConditionsMet?: Maybe<Scalars['Boolean']>;
  level3Qualifications?: Maybe<Scalars['String']>;
  level2Qualifications?: Maybe<Scalars['String']>;
  otherQualifications?: Maybe<Scalars['String']>;
};

export type SubmitApplicationResponse = {
  __typename?: 'SubmitApplicationResponse';
  referenceNumber: Scalars['ID'];
  autoOfferResult: AutoOfferResult;
};


export type PublishedCourseFragment = (
  { __typename?: 'PublishedCourse' }
  & Pick<PublishedCourse, 'id' | 'title' | 'courseCode' | 'ucasCode' | 'school' | 'collegeLocations' | 'complexConditions'>
);

export type GetPublishedCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublishedCoursesQuery = (
  { __typename?: 'Query' }
  & { publishedCourses: Array<(
    { __typename?: 'PublishedCourse' }
    & PublishedCourseFragment
  )> }
);

export type SubmitApplicationResponseFragment = (
  { __typename?: 'SubmitApplicationResponse' }
  & Pick<SubmitApplicationResponse, 'referenceNumber'>
  & { autoOfferResult: (
    { __typename?: 'AutoOfferResult' }
    & Pick<AutoOfferResult, 'outcome' | 'ucasTariffMet' | 'gcseEnglishMet' | 'gcseMathsMet' | 'twoPlusALevelRequirementMet' | 'courseSpecificConditionsMet'>
  ) }
);

export type SubmitApplicationMutationVariables = Exact<{
  attributes: SubmitApplicationAttributes;
}>;


export type SubmitApplicationMutation = (
  { __typename?: 'Mutation' }
  & { submitApplication: (
    { __typename?: 'SubmitApplicationResponse' }
    & SubmitApplicationResponseFragment
  ) }
);

export const PublishedCourseFragmentDoc = gql`
    fragment PublishedCourse on PublishedCourse {
  id
  title
  courseCode
  ucasCode
  school
  collegeLocations
  complexConditions
}
    `;
export const SubmitApplicationResponseFragmentDoc = gql`
    fragment SubmitApplicationResponse on SubmitApplicationResponse {
  referenceNumber
  autoOfferResult {
    outcome
    ucasTariffMet
    gcseEnglishMet
    gcseMathsMet
    twoPlusALevelRequirementMet
    courseSpecificConditionsMet
  }
}
    `;
export const GetPublishedCoursesDocument = gql`
    query GetPublishedCourses {
  publishedCourses {
    ...PublishedCourse
  }
}
    ${PublishedCourseFragmentDoc}`;

/**
 * __useGetPublishedCoursesQuery__
 *
 * To run a query within a React component, call `useGetPublishedCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishedCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishedCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublishedCoursesQuery(baseOptions?: Apollo.QueryHookOptions<GetPublishedCoursesQuery, GetPublishedCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublishedCoursesQuery, GetPublishedCoursesQueryVariables>(GetPublishedCoursesDocument, options);
      }
export function useGetPublishedCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublishedCoursesQuery, GetPublishedCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublishedCoursesQuery, GetPublishedCoursesQueryVariables>(GetPublishedCoursesDocument, options);
        }
export type GetPublishedCoursesQueryHookResult = ReturnType<typeof useGetPublishedCoursesQuery>;
export type GetPublishedCoursesLazyQueryHookResult = ReturnType<typeof useGetPublishedCoursesLazyQuery>;
export type GetPublishedCoursesQueryResult = Apollo.QueryResult<GetPublishedCoursesQuery, GetPublishedCoursesQueryVariables>;
export const SubmitApplicationDocument = gql`
    mutation SubmitApplication($attributes: SubmitApplicationAttributes!) {
  submitApplication(attributes: $attributes) {
    ...SubmitApplicationResponse
  }
}
    ${SubmitApplicationResponseFragmentDoc}`;
export type SubmitApplicationMutationFn = Apollo.MutationFunction<SubmitApplicationMutation, SubmitApplicationMutationVariables>;

/**
 * __useSubmitApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitApplicationMutation, { data, loading, error }] = useSubmitApplicationMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useSubmitApplicationMutation(baseOptions?: Apollo.MutationHookOptions<SubmitApplicationMutation, SubmitApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitApplicationMutation, SubmitApplicationMutationVariables>(SubmitApplicationDocument, options);
      }
export type SubmitApplicationMutationHookResult = ReturnType<typeof useSubmitApplicationMutation>;
export type SubmitApplicationMutationResult = Apollo.MutationResult<SubmitApplicationMutation>;
export type SubmitApplicationMutationOptions = Apollo.BaseMutationOptions<SubmitApplicationMutation, SubmitApplicationMutationVariables>;