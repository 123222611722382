import { Checkbox, Col, Result, Space, Spin, Typography, Button } from "antd";
import React, { useCallback, useState } from "react";
import {
  ApplicationForm,
  ApplicationFormData,
} from "../../components/ApplicationForm";
import { Offer, Refer, Reject } from "../../components/ApplyResult";
import {
  SubmitApplicationResponse,
  useSubmitApplicationMutation,
  useGetPublishedCoursesQuery,
} from "../../generated-types";

interface ResultData {
  response: SubmitApplicationResponse;
  applicantName: string;
  courseTitle: string;
  courseUCASCode: string;
  courseLocationCode: string;
}

type Outcome = "Offer" | "Reject" | "Refer";

export const postOutcomeToParentWindow = (
  outcome: Outcome,
  courseTitle: string
) => {
  if (window.parent === window) return;

  window.parent.postMessage(
    {
      method: "SUBMIT_RESULT",
      data: {
        outcome: outcome.toLowerCase(),
        courseTitle: courseTitle,
      },
    },
    "*"
  );
};

export const ApplyIndexPage: React.FC = () => {
  const [submitResult, setSubmitResult] = useState<ResultData | undefined>();

  const [checksComplete, setChecksComplete] = useState(false);

  const url = new URL(window.location.href);
  const urlCourseUCASCode = url.searchParams.get("course-id") || "";

  const {
    data: coursesData,
    loading: coursesLoading,
    error: coursesError,
  } = useGetPublishedCoursesQuery({
    fetchPolicy: "no-cache",
  });

  const [submitApplication, { loading: submitLoading }] =
    useSubmitApplicationMutation();

  const handleSubmit = useCallback(
    (data: ApplicationFormData, form) => {
      submitApplication({
        variables: {
          attributes: {
            firstName: data.firstName.trim(),
            surname: data.surname.trim(),
            emailAddress: data.emailAddress,
            mobileNumber: data.mobilePhoneNumber,
            mobileNumberRegion: "GB",
            ucasNumber: data.ucasNumber === "" ? null : data.ucasNumber,
            course: data.courseId,
            college: data.college === "" ? null : data.college,
            ucasPoints: data.ucasPoints! as number,
            gcseEnglishGrade: data.gcseEnglishGrade as number,
            gcseMathsGrade: data.gcseMathsGrade as number,
            twoPlusALevelRequirementMet: true,
            courseSpecificConditionsMet:
              data.courseSpecificConditionsMet === ""
                ? null
                : data.courseSpecificConditionsMet,
            level3Qualifications:
              data.level3Qualifications === ""
                ? null
                : data.level3Qualifications,
            level2Qualifications: null,
            otherQualifications: null,
          },
        },
      })
        .then((res) => {
          if (res.data) {
            setSubmitResult({
              response: res.data.submitApplication,
              applicantName: `${data.firstName} ${data.surname}`,
              courseTitle: data.course!.title,
              courseUCASCode: data.course!.ucasCode,
              courseLocationCode: data.college!,
            });
            const outcome = res.data.submitApplication.autoOfferResult
              .outcome as Outcome;
            postOutcomeToParentWindow(outcome, data.course!.title);
          }
        })
        .catch((error) => {
          form.setStatus(error.message);
        })
        .finally(() => {
          window.scrollTo(0, 0);
          form.setSubmitting(false);
        });
    },
    [submitApplication]
  );

  return (
    <Space
      direction="vertical"
      style={{ alignItems: "stretch", width: "100%" }}
      align="center"
    >
      {(!checksComplete && (
        <PreApplicationChecks onDone={() => setChecksComplete(true)} />
      )) ||
        (!!coursesError && (
          <Result
            status="500"
            title="Error loading course list"
            subTitle={coursesError.message}
          />
        )) ||
        (!submitResult && coursesData && coursesData.publishedCourses && (
          <Spin size="large" spinning={coursesLoading || submitLoading}>
            <ApplicationForm
              courseUCASCode={urlCourseUCASCode}
              courses={coursesData.publishedCourses}
              handleSubmit={handleSubmit}
            />
          </Spin>
        )) || (
          <Col>
            <OfferResult result={submitResult} />
          </Col>
        )}
    </Space>
  );
};

const PreApplicationChecks: React.FC<{ onDone: () => void }> = ({ onDone }) => {
  const [isUK, setIsUK] = useState(false);
  const [gotAllResults, setGotAllResults] = useState(false);
  return (
    <Space direction="vertical" style={{ display: "float" }}>
      <Typography.Title>
        First, check you're eligible for clearing ✅
      </Typography.Title>
      <Typography.Paragraph>
        Clearing is only open to UK applicants, International and EU applicants
        should call our International Office on +44 115 824 2657
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Checkbox value={isUK} onChange={(e) => setIsUK(e.target.checked)}>
          I confirm I am a UK applicant
        </Checkbox>
      </Typography.Paragraph>
      <Typography.Paragraph>
        You will need all your results to make a Clearing application. If you
        are still waiting on some grades, why not bookmark this page and we'll
        look forward to receiving your application as soon as you're ready
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Checkbox
          value={gotAllResults}
          onChange={(e) => setGotAllResults(e.target.checked)}
        >
          I've received all of my results
        </Checkbox>
      </Typography.Paragraph>
      <Button
        type="primary"
        disabled={!isUK || !gotAllResults}
        onClick={onDone}
      >
        LET'S GO!
      </Button>
    </Space>
  );
};

const OfferResult: React.FC<{
  result: ResultData | undefined;
}> = ({ result }) => {
  if (!result) return <h1>somethign went wrong</h1>;
  switch (result.response.autoOfferResult.outcome) {
    case "Offer":
      return (
        <Offer
          applicationReferenceNumber={result.response.referenceNumber}
          courseTitle={result.courseTitle}
          courseUCASCode={result.courseUCASCode}
          courseLocationCode={result.courseLocationCode}
        />
      );
    case "Refer":
      return (
        <Refer
          applicationReferenceNumber={result.response.referenceNumber}
          courseTitle={result.courseTitle}
          courseUCASCode={result.courseUCASCode}
          courseLocationCode={result.courseLocationCode}
        />
      );
    case "Reject":
      return (
        <Reject
          applicationReferenceNumber={result.response.referenceNumber}
          courseTitle={result.courseTitle}
          courseUCASCode={result.courseUCASCode}
          courseLocationCode={result.courseLocationCode}
          ucasTariffMet={result.response.autoOfferResult.ucasTariffMet}
          gcseEnglishMet={result.response.autoOfferResult.gcseEnglishMet}
          gcseMathsMet={result.response.autoOfferResult.gcseMathsMet}
          twoPlusALevelRequirementMet={
            result.response.autoOfferResult.twoPlusALevelRequirementMet
          }
          courseSpecificConditionsMet={
            result.response.autoOfferResult.courseSpecificConditionsMet
          }
        />
      );
  }
  return null;
};
