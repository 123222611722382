import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Alert, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./App.less";
import { createClient } from "./apollo";
import { ErrorMessage } from "./components/Error";
import { ClientSideConfig, getClientSideConfig } from "./config";
import { ApplyIndexPage } from "./pages/apply";

export const App: React.FC = () => {
  const [config, setConfig] = useState<ClientSideConfig | undefined>();
  const [configLoading, setConfigLoading] = useState(false);
  const [configError, setConfigError] = useState<string | undefined>();

  useEffect(() => {
    setConfigLoading(true);
    getClientSideConfig()
      .then((config) => {
        setConfig(config);
        if (!config.sentryDsn) return;

        Sentry.init({
          dsn: config.sentryDsn,
          environment: config.environment,
        });
      })
      .catch((error) => setConfigError(error.message))
      .finally(() => setConfigLoading(false));
  }, []);

  return (
    <Row justify="center" style={{ margin: 20 }}>
      <Col style={{ width: 900 }}>
        <Spin spinning={configLoading}>
          {(configError && (
            <Alert
              message="Couldn't load config"
              description={configError}
              type="error"
            />
          )) || (
            <Sentry.ErrorBoundary
              showDialog={false}
              fallback={({ error }) => <ErrorMessage error={error} />}
            >
              <ApolloProvider
                client={createClient(config ? config!.apiUrl : "")}
              >
                <ApplyIndexPage />
              </ApolloProvider>
            </Sentry.ErrorBoundary>
          )}
        </Spin>
      </Col>
    </Row>
  );
};
