import {
  Alert,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { FormikBag, FormikProps, withFormik } from "formik";
import {
  Form,
  Input,
  Radio,
  ResetButton,
  Select,
  SubmitButton,
} from "formik-antd";
import React from "react";
import * as Yup from "yup";
import "yup-phone";
import { mapCollegeIDToName } from "../college";

const layout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 24 },
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 24 },
  },
  colon: false,
  labelAlign: "left" as const,
};

export interface ApplicationFormData {
  course?: Course;
  courseId: string;
  college?: string;
  firstName: string;
  surname: string;
  emailAddress: string;
  mobilePhoneNumber: string;
  existingUcasApplication: boolean | "";
  ucasNumber: string;
  ucasPoints?: number | "";
  gcseEnglishGrade: number | "";
  gcseMathsGrade: number | "";
  twoPlusALevelRequirementMet: boolean | "";
  courseSpecificConditionsMet: boolean | "";
  level3Qualifications: string;
  level2Qualifications: string;
  otherQualifications: string;
}

interface Course {
  id: string;
  title: string;
  collegeLocations: string[];
  complexConditions?: string | null;
  ucasCode: string;
}

const onFocus = (event: React.FocusEvent) => {};

export const ApplicationFormLayout = (
  props: FormProps & FormikProps<ApplicationFormData>
) => {
  const {
    values,
    // errors,
    // touched,
    // handleChange,
    // handleBlur,
    // handleSubmit,
    // isSubmitting,
    isValid,
    dirty,
    status,
    setFieldValue,
    setFieldTouched,
  } = props;

  return (
    <Form {...layout}>
      {status && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            showIcon
            type="error"
            message="Error submitting application"
            description={status}
          />
          <br />
        </Space>
      )}
      <Typography.Title>Great, let's do this! 📝</Typography.Title>

      <Form.Item
        label="Which course would you like to apply for?"
        name="courseId"
        required
      >
        <Select
          size="large"
          autoFocus
          name="courseId"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option!.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optA, optB) =>
            optA.title.toLowerCase().localeCompare(optB.title.toLowerCase())
          }
          onChange={(val) => {
            const course = props.courses.find((c) => c.id === val);
            setFieldValue("course", course);
            if (
              values.college &&
              course!.collegeLocations.indexOf(values.college)
            ) {
              setFieldValue("college", undefined);
              setFieldTouched("college", false);
            }
            setFieldValue("courseSpecificConditionsMet", "");
          }}
        >
          {props.courses
            .sort((a, b) => (a.title < b.title ? -1 : 1))
            .map((course) => (
              <Select.Option
                title={`${course.title}|${course.ucasCode}`}
                key={course.id}
                value={course.id}
              >
                <Row justify="space-between">
                  <Col>{course.title}</Col>
                  <Col>
                    <Tooltip overlay={`UCAS Code: ${course.ucasCode}`}>
                      <Tag>{course.ucasCode}</Tag>
                    </Tooltip>
                  </Col>
                </Row>
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      {values.course && values.course.collegeLocations.length > 0 && (
        <Form.Item
          name="college"
          label="College"
          required
          extra="This course is studied at one or more colleges, select the college you would like to attend."
        >
          <Select
            name="college"
            size="large"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!.children!.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {values.course.collegeLocations.map((col) => (
              <Select.Option key={col} value={col}>
                {mapCollegeIDToName(col)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Divider />
      <Form.Item label="First Name" name="firstName" required>
        <Input size="large" name="firstName" onFocus={onFocus} />
      </Form.Item>
      <Form.Item label="Surname" name="surname" required>
        <Input size="large" name="surname" onFocus={onFocus} />
      </Form.Item>
      <Form.Item label="Email Address" name="emailAddress" required>
        <Input size="large" name="emailAddress" onFocus={onFocus} />
      </Form.Item>
      <Form.Item label="Mobile Phone Number" name="mobilePhoneNumber" required>
        <Input
          size="large"
          name="mobilePhoneNumber"
          type="tel"
          onFocus={onFocus}
        />
      </Form.Item>
      <Form.Item
        name="existingUcasApplication"
        required
        label={
          <span>
            Have you made a UCAS application for the{" "}
            <strong>current admissions year?</strong>
          </span>
        }
      >
        <Radio.Group size="large" name="existingUcasApplication">
          <Radio size="large" value={true} name="existingUcasApplication">
            Yes
          </Radio>
          <Radio size="large" value={false} name="existingUcasApplication">
            No
          </Radio>
        </Radio.Group>
      </Form.Item>
      {values.existingUcasApplication && (
        <Form.Item label="UCAS Number" name="ucasNumber">
          <Input size="large" name="ucasNumber" onFocus={onFocus} />
        </Form.Item>
      )}

      <Divider />

      <Form.Item
        label="UCAS Points"
        name="ucasPoints"
        required
        extra="Number of UCAS points the you have achieved."
      >
        <Input size="large" name="ucasPoints" type="number" onFocus={onFocus} />
      </Form.Item>

      <Form.Item
        label="GCSE English Language Grade"
        name="gcseEnglishGrade"
        required
        extra="If you have taken Functional Skills Level 2 or Key Skills Level 3, select
grade 4(C)."
      >
        <Select
          size="large"
          name="gcseEnglishGrade"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option!.children!.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onFocus={onFocus}
        >
          <Select.Option value={0}>None / U</Select.Option>
          <Select.Option value={1}>1 (G)</Select.Option>
          <Select.Option value={2}>2 (E)</Select.Option>
          <Select.Option value={3}>3 (D)</Select.Option>
          <Select.Option value={4}>4 (C)</Select.Option>
          <Select.Option value={5}>5</Select.Option>
          <Select.Option value={6}>6 (B)</Select.Option>
          <Select.Option value={7}>7 (A)</Select.Option>
          <Select.Option value={8}>8 (A*)</Select.Option>
          <Select.Option value={9}>9</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="GCSE Maths Grade"
        name="gcseMathsGrade"
        required
        extra="If you have taken Functional Skills Level 2 or Key Skills Level 3, select
grade 4(C)."
      >
        <Select
          size="large"
          name="gcseMathsGrade"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option!.children!.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onFocus={onFocus}
        >
          <Select.Option value={0}>None / U</Select.Option>
          <Select.Option value={1}>1 (G)</Select.Option>
          <Select.Option value={2}>2 (E)</Select.Option>
          <Select.Option value={3}>3 (D)</Select.Option>
          <Select.Option value={4}>4 (C)</Select.Option>
          <Select.Option value={5}>5</Select.Option>
          <Select.Option value={6}>6 (B)</Select.Option>
          <Select.Option value={7}>7 (A)</Select.Option>
          <Select.Option value={8}>8 (A*)</Select.Option>
          <Select.Option value={9}>9</Select.Option>
        </Select>
      </Form.Item>

      {values.course && values.course.complexConditions && (
        <Form.Item
          label="Course Specific Conditions"
          name="courseSpecificConditionsMet"
          required
          extra="Have you met the course specific conditions?"
        >
          <Card title={values.course.title} size="small">
            {values.course.complexConditions}
          </Card>
          <br />
          <Radio.Group size="large" name="courseSpecificConditionsMet">
            <Radio size="large" value={true} name="courseSpecificConditionsMet">
              Yes
            </Radio>
            <Radio
              size="large"
              value={false}
              name="courseSpecificConditionsMet"
            >
              No
            </Radio>
          </Radio.Group>
        </Form.Item>
      )}

      <Form.Item
        label="Qualifications and Work Experience"
        name="level3Qualifications"
        extra="List the type, subject and grade of your qualifications."
      >
        <Input.TextArea
          size="large"
          autoSize={{ minRows: 2 }}
          name="level3Qualifications"
          onFocus={onFocus}
        />
      </Form.Item>
      <Divider />
      <Typography.Paragraph>
        By submitting your details you consent to the University contacting you
        about your application, our courses, facilities and the University
        itself.
      </Typography.Paragraph>

      <Typography.Paragraph>
        Please be assured that we will treat your information with the utmost
        care in accordance with relevant data protection legislation and the
        University's policy on data protection, which is available to view on
        the University's website.
      </Typography.Paragraph>

      <Typography.Paragraph>
        We will only pass your details onto relevant third parties in relation
        to your application to the University of Hertfordshire. Your data will
        be stored in our CRM systems which will be used to support you in your
        application and keep you informed about the University.
      </Typography.Paragraph>

      <Divider />
      <Space style={{ justifyContent: "space-between", width: "100%" }}>
        <ResetButton danger tabIndex={-1}>
          Reset
        </ResetButton>
        <SubmitButton size="large" disabled={!isValid && !dirty}>
          Apply
        </SubmitButton>
      </Space>
    </Form>
  );
};

export const schema = Yup.object()
  .shape({
    courseId: Yup.string().required("Must select course"),
    college: Yup.string().when("course", {
      is: (course: Course) => course && course.collegeLocations.length > 0,
      then: Yup.string().test({
        name: "college available",
        exclusive: false,
        message: "Select available college for this course",
        test: function (value) {
          return this.parent.course.collegeLocations.indexOf(value) >= 0;
        },
      }),
    }),
    firstName: Yup.string().trim().required("Must provide first name").max(200),
    surname: Yup.string().trim().required("Must provide surname").max(200),
    emailAddress: Yup.string()
      .trim("Remove spaces from start or end")
      .required("Enter email address")
      .email("Must be a valid email address")
      .max(200),
    mobilePhoneNumber: Yup.string()
      .trim("Remove spaces from start or end")
      .phone("GB", true, "Must be valid UK phone number")
      .required("Must enter mobile phone number")
      .max(20),
    existingUcasApplication: Yup.boolean().required(
      "Confirm if you have already made a UCAS application this year"
    ),
    ucasNumber: Yup.string().matches(
      /^[0-9]{10}$/,
      "Must be 10 digit UCAS number"
    ),
    ucasPoints: Yup.number()
      .min(0, "Enter a valid number of UCAS Points")
      .max(999, "Enter a valid number of UCAS Points")
      .required("Enter number of UCAS points you have"),
    gcseEnglishGrade: Yup.number().required(
      "Enter your GCSE English Language (or equivalent) grade"
    ),
    gcseMathsGrade: Yup.number().required(
      "Enter your GCSE Maths (or equivalent) grade"
    ),
    courseSpecificConditionsMet: Yup.boolean().when("course", {
      is: (course: Course) => course && course.complexConditions,
      then: Yup.boolean().required(
        "Confirm if you have met the course specific conditons above"
      ),
    }),
    level3Qualifications: Yup.string().max(500),
  })
  .strict();

export type HandleSubmit<Props, Data> = (
  values: ApplicationFormData,
  bag: FormikBag<Props, Data>
) => void;

export interface FormProps {
  courseUCASCode?: string | null;
  courses: Course[];
  handleSubmit: HandleSubmit<FormProps, ApplicationFormData>;
}

export const ApplicationForm = withFormik<FormProps, ApplicationFormData>({
  mapPropsToValues: (props) => {
    let defaultCourse: Course | undefined;
    const matchingCourses = props.courses.filter(
      (c) => c.ucasCode.toUpperCase() === props.courseUCASCode!.toUpperCase()
    );
    if (matchingCourses.length === 1) defaultCourse = matchingCourses[0];

    return {
      course: defaultCourse,
      courseId: defaultCourse ? defaultCourse.id : "",
      firstName: "",
      surname: "",
      emailAddress: "",
      mobilePhoneNumber: "",
      existingUcasApplication: "",
      ucasNumber: "",
      ucasPoints: "",
      gcseEnglishGrade: "",
      gcseMathsGrade: "",
      twoPlusALevelRequirementMet: "",
      courseSpecificConditionsMet: "",
      level3Qualifications: "",
      level2Qualifications: "",
      otherQualifications: "",
    };
  },
  validationSchema: schema,
  handleSubmit(data, bag) {
    bag.props.handleSubmit(data, bag);
  },
})(ApplicationFormLayout);
